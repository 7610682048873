.userdef-container{
    display: flex;
    align-items: center;
}
.userDef {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #61affe;
  width: 50%;
}
