.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 55px;
  height: 30px;
  background: #d83c66;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  border-radius: 45px;
  transition: 0.1s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 20px;
}

.switch-label {  
  text-align: left;
  font: normal normal normal 12px/10px Source Sans Pro;
  letter-spacing: 0px;
  color: #F58447;
  opacity: 1;
  font-size: 12px !important
}
