#loading-img {
    background: url('spinner.gif') center center no-repeat;
    height: 100%;
    z-index: 20;
}

.overlay {
    background: #262525;
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.5;
}