.tags-input-container{
    border: 1px solid lightgray;
    padding: .5em;
    border-radius: 10px;        
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em;
}

.tag-item{
    background-color: rgb(218, 216, 216);
    display: inline-block;
    padding-left: 0.5em;
    border-radius: 10px;
    font-size: 0.85em;    
}
.tag-item .close{
    height: 20px;
    width: 20px;
    background-color: rgb(48, 48, 48);
    color: #fff;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    font-size: 12px;
    cursor: pointer;
}

.tags-input{
    flex-grow: 1;
    padding: 0 0;
    border: none;
    outline: none;
    font-size: 0.85em;
}