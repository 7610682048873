html, body{
  background-color:#f4f6f9;
}

.consolidated-wrapper {
  height: 70vh;
  padding-right: 15px;
  overflow-y: scroll;
}

table th {
  background: #6b9dbc 0% 0% no-repeat padding-box;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-14) / 30px
    var(--unnamed-font-family-source-sans-pro);
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.main-footer {
  height: 57px !important;
}

.user-image {
  width: 38px;
  height: 38px;
}

.table-header {
  border-color: #6b9dbc !important;
}

table tr,
label,
.link-paginator,
.form-control2 {
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 14px/25px Source Sans Pro !important;
  letter-spacing: 0px;
  color: #0367a5;
  opacity: 1;
}

.group-field-label {
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: Source Sans Pro !important;
  letter-spacing: 0px;
  color: #f58447;
  opacity: 1;
}

#setup-details-nav .nav-link.active,
#setup-details-nav .show > .nav-link {
  color: #fff;
  background-color: #6b9dbc;
}

.custom-control-input {
  transform: scale(1.4);
}
.main-sidebar {
  position: fixed !important;
}

@media only screen and (max-width: 990px) {
  .nav-link p,  .nav-header {
    display: none !important;
  }

  .brand-text {
    visibility: hidden;
  }

  .main-sidebar {
    margin-left: 0;
    width: 4.0rem;
    overflow-x: hidden !important;
  }

  .content-wrapper {
    padding: 0 4rem;
  }
}


.button-control-warning {
  background: var(---f58447) 0% 0% no-repeat padding-box;
  background: #f58447 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #fff;
  opacity: 1;
}

.button-control-secondary {
  background: var(---f58447) 0% 0% no-repeat padding-box;
  background: #6c757d 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #fff;
  opacity: 1;
}

.form-control {
  color: #6b9dbc !important;
}

.title-label {
  height: 22px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) / 30px
    var(--unnamed-font-family-source-sans-pro);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 16px/30px Source Sans Pro;
  letter-spacing: 0px;
  color: #0367a5;
  opacity: 1;
}

th.actions {
  width: 10px;
}

td.center {
  text-align: center;
}

.btn-success {
  background-color: #5ebe74 !important;
  border-color: #5ebe74 !important;
  border-radius: 7px;
}

.btn-primary {
  background-color: #0367a5 !important;
  border-color: #0367a5 !important;
  border-radius: 7px;
}

.btn-secondary {
  border-radius: 7px;
}


.btn-warning {
  background-color: #f58447 !important;
  border-color: #f58447 !important;
  border-radius: 7px;
}

.space {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
}

[class*="vspace-"] {
  display: none;
}

.space-32,
[class*="vspace-32"] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 32px 0 31px;
}

.space-30,
[class*="vspace-30"] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 30px 0 29px;
}

.space-28,
[class*="vspace-28"] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 28px 0 27px;
}

.space-26,
[class*="vspace-26"] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 26px 0 25px;
}

.space-24,
[class*="vspace-24"] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 24px 0 23px;
}

.space-22,
[class*="vspace-22"] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 22px 0 21px;
}

.space-20,
[class*="vspace-20"] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 20px 0 19px;
}

.space-18,
[class*="vspace-18"] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 18px 0 17px;
}

.space-16,
[class*="vspace-16"] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 16px 0 15px;
}

.space-14,
[class*="vspace-14"] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 14px 0 13px;
}

.space-12,
[class*="vspace-12"] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0 11px;
}

.space-10,
[class*="vspace-10"] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 10px 0 9px;
}

.space-8,
[class*="vspace-8"] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 8px 0 7px;
}

.space-6,
[class*="vspace-6"] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 6px 0 5px;
}

.space-4,
[class*="vspace-4"] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 4px 0 3px;
}

.space-2,
[class*="vspace-2"] {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 2px 0 1px;
}

.required-field::after {
  content: "*";
  color: red;
  font-size: 1.3rem;
}

.card-header {
  color: rgb(3, 103, 165);
  background-color: #f4f6f9; 
}

textarea {
  resize: none;
  font-size: 0.8rem;
}

.card-body label {
  font-size: 0.8rem !important;
}

.card {
  margin-top: 10px;
  margin-bottom: 10px; 
  
}

.validation-error {
  font-size: 0.8rem;
  color: red;
}

.tooltip {
  width: 250px;
  background-color: #013059;
  font-size: 0.80rem;  
}

.tooltip h5 {
  text-align: center;
}

.description {
  font-style: italic;
  font-size: 0.80rem;
  color: #0367a5;
}

.overflow-ellipsis {
  text-overflow: ellipsis;    
}

.card-col{
  background-color: #f4f6f9;
  cursor: pointer;
  
}

.icon-hover{
  transition-property: transform;
  transition-duration: 0.5s;
}
.icon-hover:hover{
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

 .stats:hover{
  font-size: 15px;
  font-weight: 500;
  color: #9a8e8e;
  
}

.stats:hover{
  transition-property: transform;
  transition-duration: 0.5s;
 
}

.card-stats {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
  background-color: #FFFFFF;
}

.card-stats .card-body {
  padding: 15px 15px 10px 15px;
}

.card-stats .card-category {
  font-weight: 400;
  color: #9A9A9A;
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 1.4em;
}

.card-stats .card-title {
  margin: 0;
  color: #333333;
  float: right;
  font-weight: 350;
  font-size: 16px;
}

.card-stats .card-footer {
  padding-top: 0;
  background-color: transparent;
  line-height: 30px;
  border-top: none !important;
  font-size: 14px;
  padding: 5px 0;
}

.card-stats .stats {
  color: #a9a9a9;
}

.card-stats .card-body .numbers {
  font-size: 1.8rem;
  text-align: right;
}

.card-stats .card-footer {
  padding: 0px 15px 10px 15px;
}

.card-stats .icon-big {
  font-size: 3em;
  min-height: 64px;
}

/* Added for Dashboard*/
.constant-container-background{
  background-color:#f4f6f9; 
}

.input-date{
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.card.background {
  background-color: transparent;
}

.logout-link {
  margin-left: 110px
}
